.Username {
  font-weight: 600;
  font-family: "Lato", sans-serif !important;
  font-size: 23px;
  padding-top: 10px;
  margin: 0;
}
.text_field {
  width: 100%;
}

.multiselectdropdown .css-yk16xz-control{
  border-radius: 8px !important;
  margin-left: -4px !important;
  height: auto !important;
}

.multiselectdropdown .css-1pahdxg-control{
  border-radius: 8px !important;
  margin-left: -4px !important;
  height: auto !important;
}

.Sort {
  font-size: 15px;
}
.UserContainer1 {
  margin-top: 46px;
}
.UserContainer2 {
  margin-top: 46px;
}
.userTable {
  border: 1px solid #eee;
}
.Overflow {
  overflow: hidden;
}
.BanUser {
  background-color: red;
  width: 80px;
  height: 27px;
  border-radius: 25px;
  margin-right: 23px;
}
.BanUser1 {
  background-color: black;
  width: 80px;
  height: 27px;
  border-radius: 25px;

  margin-right: 23px;
}
.Nointrest {
  font-size: 1rem;
  position: fixed;
  margin: 5px;
}
.Banlable {
  font-size: 12px;
  margin-left: 23px;
  padding-top: 3px;
  text-transform: none;
}
.MuiTableHeadroot {
  background: #d3d3d333;
}
.BanUser:hover {
  background-color: red;
}
.row .BanUser .mouseover:hover {
  background-color: red;
}
.MuiCheckbox-colorPrimary {
  color: #051c70;
}
.popOver {
  position: absolute;
  will-change: transform;
  top: 119px;

  left: 129px;
  transform: translate3d(935px, -28px, 0px);
}
.dropdown-menu {
  transform: translate3d(0px, 0px, 0px);
}
.dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #d3d3d359;
}
.checkIcon {
  color: green;
  margin: 6px;
}
.checkIcon1 {
  color: red;
  margin: 6px;
}
.IconSearch {
  position: absolute;
  right: 20px;
  top: 16px;
  color: gray;
}
.Banbutton1 {
  background-color: red;
  color: black;
  height: 35;
  border-radius: 40px;
}
.Banbutton2 {
  background-color: red;
  color: black;
  height: 35;
  border-radius: 80px;
}
.Banbutton {
  color: black;
  height: 35;
  border-radius: 40px;
}
.dropdown-menu {
  min-width: 0rem;
  padding: 0rem 0;
  margin: -0.7rem -100px 0px;
}

@media (max-width: 600px) {
  .UserContainer1 {
    margin-top: 0;
  }

  .sortArrow {
    margin-top: 0px;
  }
  .UserContainer2 {
    margin-top: 0px;
  }

  .selectMenu {
    /* border: 1px solid #C9C9C9;
        background-color:#F5F5F5; */
    /* padding: 2px 12px; */
    margin: 6px 0px;
    cursor: pointer;
    flex-direction: row;
    display: flex;
  }
  .formMenu {
    margin-left: 10px;
    font-size: 14px;
  }
  .marg {
    margin-left: -18px;
    margin-top: 10px;
  }
}
.font {
  border-radius: "20px";
  width: "137px";
  margin-top: "8px";
  background-color: "steelblue";
  height: "35px";
  text-align: "center";
  text-decoration: "none";
}
.delete_btn {
  background: red !important;
  color: white !important;
  text-transform: capitalize !important;
}
.cancle_btn {
  color: black !important;
  text-transform: capitalize !important;
}
.add_btn {
  font-weight: 500 !important;
  margin: 20px 0 0 9px !important;
  color: white !important;
  background-color: #684d94 !important;
  border-radius: 20px !important;
  width: 100px !important;
  height: 35px !important;
  text-align: center !important;
  text-transform: none !important;
}
.btn_user {
  background: #4797bd;
  display: flex;
  width: 100px;
  justify-content: space-between;
  /* margin: 0; */
  padding: 8px;
  border-radius: 20px;
  height: 35px;
  color: "white";
}
@media (max-width: 765px) {
  .table_scroll_view {
    overflow: hidden;
    overflow-x: scroll;
  }
}

.update_y {
  font-weight: "500";
  margin: 0px;
  width: 200px;
  height: 45px;
  text-align: right;
  text-transform: none;
  color: #684d94;
  border: none;
  background-color: white;
  text-decoration: underline;
}

@media (max-width: 765px) {
  .update_y {
    text-align: right;
    background-color: white;
    border: 1px solid #684d94;
    width: 85px;
    border-radius: 4px;
    height: 30px;
    font-size: 13px;
    font-weight: "bold";
  }
}



.tracking_names {
    background-color: white !important;
    padding: 5px;
    text-align: left;
  }
  
  .Tracker_date {
    margin-top: 0px !important;
    width: 100% !important;
  }
  
  .tracker_screenshort {
    height: 150px;
  }
  
  .Adminscreenshort {
    height: 103px !important;
  }
  
  .screenshotWithSnap {
    width: 173px;
    height: 103px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
  }
  
  .cpuinfoheading{
    font-size: 20px;
    font-weight: 600;
  }
  
  .cpulabel{
    font-size: 16px;
    margin-bottom: 0rem !important;
  }
  .cpuvalue{
    font-weight: 600;
    font-size: 16px;
  }
  
  .cpucardselect{
    background-color: #E3E1FF;
    border-radius: 8px;
    border: 2px solid #424242;
    cursor: pointer;
  }
  .memorycardselect{
    background-color: #ECE0FF;
    /* margin-left: 16px !important; */
    border-radius: 8px;
    border: 2px solid #424242;
    cursor: pointer;
  }
  .diskcardselect{
    background-color: #E6F4FF;
    border-radius: 8px;
    /* margin-left: 16px !important; */
    border: 2px solid #424242;
    cursor: pointer;
  }
  .networkcardselect{
    background-color: #FFE3E3;
    /* margin-left: 16px !important; */
    cursor: pointer;
    border: 2px solid #424242;
    border-radius: 8px;
  }
  
  .cpucard{
    background-color: #E3E1FF;
    border-radius: 8px;
    cursor: pointer;
  }
  .memorycard{
    background-color: #ECE0FF;
    /* margin-left: 16px !important; */
    border-radius: 8px;
    cursor: pointer;
  }
  .diskcard{
    background-color: #E6F4FF;
    border-radius: 8px;
    /* margin-left: 16px !important; */
    cursor: pointer;
  }
  .networkcard{
    background-color: #FFE3E3;
    /* margin-left: 16px !important; */
    cursor: pointer;
    border-radius: 8px;
  }
  
  .snapImage {
    width: 40px;
    margin-right: 4px;
    margin-bottom: 4px;
    border-radius: 50%;
  }
  
  .detailedSnap {
    width: 126px;
    padding-right: 4px;
    padding-bottom: 4px;
  }
  
  .progress-custom {
    background-color: #684d94 !important;
  }
  .bg-success {
    background-color: #684d94 !important;
  }
  .progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #684d94 !important;
    transition: width 0.6s ease;
  }
  .table_view {
    display: flex;
    justify-content: space-between;
    padding: 20px;
  }
  
  .table_text {
    margin: 2px;
    font-size: 16px;
    padding-left: 50px;
  }
  
  .table_act {
    text-align: left;
    padding: 15px !important;
  }
  
  .plus_icon {
    font-size: 20px !important;
  }
  
  .table_border {
    border-bottom: 1px solid #ddd;
  }
  
  /*****tracking styles ****/
  .tracker_img {
    display: flex;
    justify-content: space-between;
    padding: 0px 2px 10px 10px;
    /* margin-right: 10px; */
    margin: 5px 10px 4px 10px;
  }
  
  .tracker_value {
    background: white !important;
    border: 1px solid #684d94 !important;
    border-radius: 22px !important;
    /* width     : 300px; */
    padding: 3px 0px 3px 8px !important;
  }
  
  .tracker_in {
    padding-left: 7px;
    margin: 0;
    /* padding-top: 0px; */
    font-size: 16px;
  }
  
  .time_trac {
    float: left;
    margin-right: 25px;
  }
  
  .abovefiftycolor {
    color: #684d94 !important;
  }
  .abovetwentyfivecolor {
    color: #b1b1b1 !important;
    margin-left: 12px !important;
  }
  .belowtwentyfive {
    color: #ff0404 !important;
    margin-left: 12px !important;
  }
  
  @media (max-width: 600px) {
    .time_trac {
      overflow: hidden;
      border-right: 1px solid #ddd;
      margin: 0 15px;
    }
  }
  
  .tracking-activity-btn {
    float: right;
    position: relative;
    top: 10px;
  }
  
  .time-track-overview {
    padding: 15px;
    display: flow-root;
  }
  
  @media (max-width: 600px) {
    .time-track-overview {
      display: inline-flex;
      overflow-x: hidden;
      padding-right: 0px;
      padding-left: 0px;
    }
  }
  
  .date_pick {
    background: transparent !important;
    border: none !important;
    font-size: 20px !important;
    width: 50%;
  }
  .date_pick1 {
    margin-left: -14px !important;
  }
  
  .picker_cross {
    font-size: 18px;
    padding: 0px -25px 10px 9px;
    /* margin: 10px 0px 10px -35px; */
    /* bottom: 74%; */
    text-align: center;
    margin-left: 2px;
  }
  
  .left_pick {
    margin-left: -95px !important;
    width: 80% !important;
    cursor: pointer;
  }
  
  .start_pick {
    margin-left: 10px !important;
    cursor: pointer;
  }
  
  .month_pick {
    font-size: 18px;
    font-weight: 500;
  }
  
  .people_icon {
    font-size: 16px !important;
    margin: 5px;
  }
  
  .shadowBox {
    box-shadow: none !important;
  }
  .AlignCenter {
    text-align: center;
  }
  .menuItems {
    transform: translate3d(-47px, -11px, 0px) !important;
    margin-top: 0px !important;
    margin-left: -75px !important;
  }
  
  .employeAction {
    background-color: white;
    width: 115px;
    height: 35px;
    padding: 5px;
    border-radius: 40px;
    justify-content: flex-end;
    border: 2px solid rgb(0, 128, 0);
    margin-top: -36px;
    /* position     : absolute;
      right           : 126px;
      top             : 179px; */
    /* border          : 1px solid rgb(68, 152, 190); */
  }
  
  .optionfield {
    width: 100px;
    position: absolute;
    right: 6px;
    top: 35px;
  }
  
  .modalOption {
    font-size: 13px;
    padding: 10px;
    margin: 0px;
    transition: 0 0.5s;
  }
  
  .modalList {
    font-size: 10px;
    padding: 10px;
    margin: 0px;
    transition: 0 0.5s;
  }
  
  .div_scroll {
    height: 600px;
    overflow-y: scroll;
    /* width  : 178%; */
    overflow-x: hidden;
  }
  
  .close_view {
    display: flex;
    justify-content: space-between;
  }
  
  .close_png {
    margin-top: -38px;
    /* margin-right: -17px; */
    margin-left: -50px;
    background: white;
    width: 35px;
    padding: 9px;
    height: 35px;
    /* align-items : center; */
    border-radius: 32px;
    display: flex;
    cursor: pointer;
  }
  
  .modalOption:hover {
    opacity: 1;
    background: #ddd;
  }
  
  .tableActivity {
    font-size: 16px;
    padding: 9px 0px 3px 8px !important;
    text-align: left;
  }
  
  /*Time tracker employe*/
  .employeTracker {
    justify-content: space-between;
    display: flex;
    padding: 10px;
  }
  
  .optionEmpolyee {
    position: absolute;
    right: 27px;
    top: 35px;
  }
  
  .modalList:hover {
    opacity: 1;
    background: #ddd;
  }
  
  .trackEmployee {
    margin: 4px;
  }
  
  .trackTimeEvent {
    margin: 4px;
    font-size: 12px;
    color: rgb(71, 150, 190);
  }
  
  .employetrackAction {
    background-color: white;
    width: 95px;
    height: 30px;
    padding: 5px;
    border-radius: 30px;
    justify-content: flex-end;
    border: 1px solid rgb(68, 152, 190);
  }
  
  .Report_questionsEmployee {
    font-size: 1rem !important;
    margin: 0;
  }
  
  .tableHistory {
    margin: 0;
  }
  
  .Trackertime {
    border-left: 1px solid #eee;
    padding: 10px;
  }
  
  .Trackertime1 {
    border-left: 1px solid #eee;
    padding-left: 10px;
    margin-top: 10px;
  }
  
  .Textmargin {
    margin: 0;
    font-weight: 500;
    color: #684d94;
  }
  
  .avg {
    margin: 0;
    font-size: 12px;
  }
  #select {
    color: black;
    background-color: transparent;
    border-color: black;
  }
  
  .track_btn {
    border-radius: 20px !important;
    width: 137px !important;
    margin-top: 8px !important;
    background-color: #684d94 !important;
    height: 40px !important;
    text-align: center !important;
    text-decoration: none !important;
    text-transform: none !important;
    color: white !important;
    margin-left: 10px !important;
    margin-top: 0px !important;
  }
  
  /**time tracker table styles**/
  .edit_btn1 {
    border: 1px solid #684d94 !important;
    background: white !important;
    border-radius: 17px !important;
    text-transform: none !important;
    width: 150px !important;
    color: #684d94 !important;
  }
  .edit_ico1 {
    margin-right: 10px;
    font-size: 17px !important;
  }
  .stor_text {
    color: gray;
    font-size: 15px;
    margin-left: 25px;
  }
  .link_stor {
    font-size: 12px;
    border: 1px solid gray;
    padding: 3px;
    color: gray;
    margin-left: 25px;
    width: 450px;
  }
  .setting_track {
    display: flex;
    justify-content: space-between;
  }
  .set_btn {
    display: flex;
  }
  .mg-l {
    margin-left: 10px;
  }
  .Inactive_sty {
    background: rgb(205, 204, 204);
    width: 163px;
    text-align: center;
    color: white;
    font-size: 20px;
    font-weight: 500;
    margin-right: 10px;
  }
  .inactive_text {
    padding: 25px 4px 25px 4px;
  }
  .idel_time {
    background: rgb(205, 204, 204);
    padding: 35px;
    height: 103px;
  }
  .idel_text {
    color: white;
    text-align: center;
    font-weight: 500;
    font-size: 20px;
  }
  
  @media (max-width: 1400px) {
    .img_view {
      overflow: auto;
    }
  }
  
  @media (max-width: 750px) {
    .setting_track {
      display: block;
    }
    .set_btn {
      display: block;
    }
  }
  
  .row1 {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  
  @media (max-width: 600px) {
    .row1 {
      flex-wrap: nowrap;
    }
  }
  
  .name_y {
    font-size: 17px;
    font-weight: "500";
    color: "black";
  }
  
  .types_y {
    font-size: 17px;
    font-weight: "500";
    color: "grey";
    display: "flex";
    justify-content: "flex-end";
  }
  
  @media (max-width: 765px) {
    .name_y {
      margin-left: -30px;
      min-width: max-content;
      top: -11px;
    }
  }
  
  @media (max-width: 765px) {
    .types_y {
      font-weight: "500";
      color: "grey";
      text-align: end;
      flex-direction: column;
      margin-right: -10px;
      top: -12px;
    }
  }
  
  @media (max-width: 360px) {
    .types_y {
      top: -13px;
      font: caption;
    }
  }
  
  .body-content-wrapper {
    padding-left: 250px !important;
  }
  
  @media (max-width: 600px) {
    .body-content-wrapper {
      padding: 0px 15px !important;
    }
  }
  