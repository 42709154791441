.PhoneInputInput{
    border: none !important;
}
.PhoneInputInput:focus{
    outline: none !important;
    box-shadow: none !important;
}

.remove-arrow::-webkit-inner-spin-button,
        .remove-arrow::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

.remove-arrow {
    -moz-appearance: textfield;
}