.flex{
    display: flex;
}

.paginationstyle .MuiPaginationItem-root.Mui-selected{
    background-color: #684d94;
    color: #FFF;
}

.calendericonindex svg{
    z-index: 9 !important;
}

.sidebarradius{
    width: 280px;
    height: 100vh;
}
.contentarea{
    margin-top: 32px;
    margin-left: 16px;
    margin-right: 16px;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range{
    border-radius: 50% !important;
    background-color: #684d94 !important;
  }

  .slick-prev:before, .slick-next:before{
    color: #684d94 !important;

  }

  .slick-dots{
      bottom: -12px !important
  }

.floatRight{
    float: right;
}

.secondarycolor{
    background-color: #46a238;
}


.teamSelectWidth{
    width: 200px;
}
div select{
    -webkit-appearance: none !important;
}

