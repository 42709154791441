  .buttonload {
    border: none; /* Remove borders */
    color: white; /* White text */
    font-size: 16px; /* Set a font-size */
  }
  
  /* Add a right margin to each icon */
  .fa {
    margin-left: -12px;
    margin-right: 8px;
  }