/* CSS */
.upArrow {
  position: relative;
}
.sorting-ascending .upArrow:hover::after,
.sorting-descending .downArrow:hover::after {
  content: "";
  opacity: 0;
  visibility: hidden;
}
.upArrow:hover::after {
  content: "Click here for ascending order";
  position: absolute;
  top: 170%;
  left: 50%;
  transform: translateX(-60%);
  background-color: #fff;
  color: #684d94;
  padding: 5px;
  margin-left: 10px;
  border-radius: 4px;
  font-size: 14px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
  white-space: nowrap;
}

.upArrow:hover::after {
  opacity: 1;
  visibility: visible;
}

.downArrow {
  position: relative;
}

.downArrow:hover::after {
  content: "Click here for descending order";
  position: absolute;
  top: 170%;
  left: 50%;
  transform: translateX(-60%);
  background-color: #fff;
  color: #684d94;
  padding: 5px;
  margin-left: 10px;
  border-radius: 4px;
  font-size: 14px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
  white-space: nowrap;
}

.downArrow:hover::after {
  opacity: 1;
  visibility: visible;
}

.sorting-ascending .upArrow1:hover::after,
.sorting-descending .downArrow1:hover::after {
  content: "";
  opacity: 0;
  visibility: hidden;
}

.sorting-ascendingtname .upArrow1:hover::after,
.sorting-descendingtname .downArrow1:hover::after {
  content: "";
  opacity: 0;
  visibility: hidden;
}

.sorting-ascendingtworked .upArrow:hover::after,
.sorting-descendingtworked .downArrow:hover::after {
  content: "";
  opacity: 0;
  visibility: hidden;
}
.sorting-ascendingpeople .upArrow:hover::after,
.sorting-descendingpeople .downArrow:hover::after {
  content: "";
  opacity: 0;
  visibility: hidden;
}

.sorting-ascendingename .upArrow:hover::after,
.sorting-descendingename .downArrow:hover::after {
  content: "";
  opacity: 0;
  visibility: hidden;
}

.sorting-ascendingtimelogged .upArrow:hover::after,
.sorting-descendingtimelogged .downArrow:hover::after {
  content: "";
  opacity: 0;
  visibility: hidden;
}

.sorting-ascendingproject .upArrow:hover::after,
.sorting-descendingproject .downArrow:hover::after {
  content: "";
  opacity: 0;
  visibility: hidden;
}

.sorting-ascendingeteam .upArrow:hover::after,
.sorting-descendingeteam .downArrow:hover::after {
  content: "";
  opacity: 0;
  visibility: hidden;
}

.sorting-ascendingpname .upArrow:hover::after,
.sorting-descendingpname .downArrow:hover::after {
  content: "";
  opacity: 0;
  visibility: hidden;
}

.sorting-ascendinghspent .upArrow:hover::after,
.sorting-descendinghspent .downArrow:hover::after {
  content: "";
  opacity: 0;
  visibility: hidden;
}

.sorting-ascendingbhours .upArrow:hover::after,
.sorting-descendingbhours .downArrow:hover::after {
  content: "";
  opacity: 0;
  visibility: hidden;
}

.sorting-ascendinghrem .upArrow:hover::after,
.sorting-descendinghrem .downArrow:hover::after {
  content: "";
  opacity: 0;
  visibility: hidden;
}

.sorting-ascendinghrolled .upArrow:hover::after,
.sorting-descendinghrolled .downArrow:hover::after {
  content: "";
  opacity: 0;
  visibility: hidden;
}

.sorting-ascendingworkname .upArrow:hover::after,
.sorting-descendingworkname .downArrow:hover::after {
  content: "";
  opacity: 0;
  visibility: hidden;
}

.sorting-ascendingworkstation .upArrow:hover::after,
.sorting-descendingworkstation .downArrow:hover::after {
  content: "";
  opacity: 0;
  visibility: hidden;
}

.sorting-ascendingactiveename .upArrow:hover::after,
.sorting-descendingactiveename .downArrow:hover::after {
  content: "";
  opacity: 0;
  visibility: hidden;
}

.sorting-ascendingactiveemail .upArrow:hover::after,
.sorting-descendingactiveemail .downArrow:hover::after {
  content: "";
  opacity: 0;
  visibility: hidden;
}

.sorting-ascendinginactiveename .upArrow:hover::after,
.sorting-descendinginactiveename .downArrow:hover::after {
  content: "";
  opacity: 0;
  visibility: hidden;
}

.sorting-ascendinginactiveemail .upArrow:hover::after,
.sorting-descendinginactiveemail .downArrow:hover::after {
  content: "";
  opacity: 0;
  visibility: hidden;
}

.sorting-ascendingactiveproject .upArrow:hover::after,
.sorting-descendingactiveproject .downArrow:hover::after {
  content: "";
  opacity: 0;
  visibility: hidden;
}

.sorting-ascendingteamname .upArrow:hover::after,
.sorting-descendingteamname .downArrow:hover::after {
  content: "";
  opacity: 0;
  visibility: hidden;
}

.sorting-ascendingcreated .upArrow:hover::after,
.sorting-descendingcreated .downArrow:hover::after {
  content: "";
  opacity: 0;
  visibility: hidden;
}

.sorting-ascendingcount .upArrow:hover::after,
.sorting-descendingcount .downArrow:hover::after {
  content: "";
  opacity: 0;
  visibility: hidden;
}

.upArrow1 {
  position: relative;
}

.upArrow1:hover::after {
  content: "Click here for ascending order";
  position: absolute;
  top: 170%;
  left: 265%;
  transform: translateX(-60%);
  background-color: #fff;
  color: #684d94;
  padding: 5px;
  margin-left: 10px;
  border-radius: 4px;
  font-size: 14px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
  white-space: nowrap;
}

.upArrow1:hover::after {
  opacity: 1;
  visibility: visible;
}

.downArrow1 {
  position: relative;
}

.downArrow1:hover::after {
  content: "Click here for descending order";
  position: absolute;
  top: 170%;
  left: 290%;
  transform: translateX(-60%);
  background-color: #fff;
  color: #684d94;
  padding: 5px;
  margin-left: 10px;
  border-radius: 4px;
  font-size: 14px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
  white-space: nowrap;
}

.downArrow1:hover::after {
  opacity: 1;
  visibility: visible;
}
